var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex justify-center items-center"},[_c('div',{staticClass:"mx-auto self-center"},[_c('vs-card',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col lg:w-1/5 mt-3"},[_c('imageLazy',{attrs:{"imageStyle":{ 'border-radius': '15px' },"imageClass":'doctorImage',"src":_vm.baseURL + _vm.doctor.ProfileImagePath,"placeHolderType":"doctor"}})],1),_c('div',{staticClass:"vx-col lg:w-1/3 mt-2"},[_c('h2',[_vm._v(_vm._s(_vm.$t("Dr"))+". "+_vm._s(_vm.doctor.Name))])])]),_c('div',{staticClass:"ml-10 mr-10"},[(_vm.showAppointments)?_c('v-date-picker',{staticStyle:{"width":"95%"},attrs:{"locale":_vm.$vs.rtl ? 'ar' : 'en',"min":_vm.minDate,"attributes":_vm.attrs,"min-date":_vm.minDate},on:{"input":_vm.getAppointmentSessions,"update:from-page":_vm.pageChange},model:{value:(_vm.appointmentDate),callback:function ($$v) {_vm.appointmentDate=$$v},expression:"appointmentDate"}}):_vm._e(),(_vm.showAppointments)?_c('h5',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("SelectATimeFor"))+" "+_vm._s(_vm.appointmentDateStrig)+" : ")]):_vm._e(),(_vm.showAppointments)?_c('div',{staticClass:"vx-row"},[_vm._l((_vm.sessions),function(item){return _c('div',{key:item.name,staticClass:"lg:w-1/4 mt-2 ml-2 mr-2"},[_c('div',{on:{"click":function($event){_vm.selectSession(item);
                _vm.isSelected = !_vm.isSelected;}}},[_c('vx-card',{staticStyle:{"height":"80%"},style:([
                  !item.IsAvailable
                    ? { 'background-color': 'gray' }
                    : item.ID == _vm.Session.ID && _vm.isSelected
                    ? { 'background-color': '#004477', 'font-color': 'white' }
                    : item.IsBlocked
                    ? { 'background-color': 'red', 'font-color': 'white' }
                    : {} ])},[_c('p',{staticClass:"text-center fontBook",staticStyle:{"margin-bottom":"3px"},style:([
                    item.ID == _vm.Session.ID && _vm.isSelected
                      ? { color: 'white' }
                      : item.IsBlocked
                      ? { color: 'white' }
                      : !item.IsAvailable
                      ? { color: 'white' }
                      : {} ])},[_vm._v(" "+_vm._s(item.DateFrom.slice(11, 16))+" - "+_vm._s(item.DateTo.slice(11, 16))+" ")])])],1)])}),(
              _vm.sessions.length > 0 &&
              !(_vm.sessions.findIndex(function (element) { return element.IsAvailable == true; }) >= 0)
            )?_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("onlineAppointmentSpecificDateMessage"))+" "),_c('u',{staticStyle:{"color":"blue"}},[_c('a',{attrs:{"href":'mailto:patient.service@doclinia.com' +
                  '?subject=' +
                  'Dr:' +
                  _vm.doctor.Name +
                  ',Date:' +
                  _vm.changeFormatedDate(this.appointmentDate)}},[_vm._v("patient.service@doclinia.com")])])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"mt-8 mb-8 flex flex-wrap vx-row items-center justify-end"},[_c('u',{staticStyle:{"color":"#004477","font-weight":"bold","font-size":"15px"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('closePop')}}},[_vm._v(_vm._s(_vm.$t("Back")))]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"disabled":!_vm.isSelected},on:{"click":function($event){return _vm.Proceed()}}},[_vm._v(_vm._s(_vm.$t("Save"))+" ")])],1)])])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }